.createnew-blog-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* background-color: aqua; */
}

.createnew-blog {
	width: 70%;
	display: flex;
	flex-direction: column;
}
.newblog-markdown-wrapper {
	/* background-color: aqua; */
	width: 100%;
	padding: 0.4rem;
}

label {
	margin-top: 1rem;
	font-weight: bold;
	display: flex;
	flex-direction: column;
}
label > input {
	margin-top: 0.5rem;
	padding: 0.4rem;
	font-size: 1.8rem;
	font-weight: bold;
	color: rgb(70, 70, 70);
}
.markdown-btn > button {
	background: whitesmoke;
	border-style: none;
	padding: 0.8rem;
	border-radius: 0.5rem 0.5rem 0rem 0rem;
	border: solid 1px rgb(70, 70, 70);
	border-bottom-color: whitesmoke;
	/* margin-bottom: -1px; */
	z-index: 10;
}
.markdown-preview,
.blog {
	margin-top: -1px;
	padding: 0.5rem;
	min-height: 20rem;
	min-width: 100%;
	border: rgb(70, 70, 70) solid 1px;
	border-radius: 0rem 0.2rem 0.2rem 0.2rem;
}
.blog {
	background: white;
	font-size: 1rem;
	max-width: 100%;
}
.markdown-preview {
	background: whitesmoke;
	overflow: auto;
	margin-bottom: 0.3rem;
}
.description {
	max-width: 100%;
	min-width: 100%;
	max-height: 4rem;
	min-height: 4rem;
	margin-top: 0.5rem;
	padding: 0.2rem;
	font-size: 1.2rem;
	font-weight: bold;
	color: rgb(70, 70, 70);
}
.submit-blog {
	padding: 1.2rem;
	width: max-content;
}
.createblog-title {
	width: 70%;
	padding: 0.4rem 0rem;
}

.markdowntoggle {
	position: absolute;
	background: lightblue;
}
.markdown-preview:hover {
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.markdown-btn > button:hover {
	cursor: pointer;
}
