.blogs-wrapper {
	/* background-color: yellow; */
	min-width: 60vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.create-newblog {
	/* background-color: lightblue; */
	width: 100%;
}
.blog-heading {
	color: blue;
	text-align: center;
	font-size: 3.6rem;
	font-weight: bolder;
}

.blog-items {
	/* background-color: aqua; */
	width: 100%;
	min-width: fit-content;
	/* padding: 1rem; */
}
