.blog-item-wrapper {
	/* background-color: aqua; */
	max-width: 40vw;
	height: max-content;
	padding: 1.2rem;

	margin: 0.5rem;
	border-radius: 0.5rem;
	transition: 200ms ease-out;
}

.blog-item-wrapper p {
	padding: 0.5rem 0rem;
	color: #484848;
	/* background-color: aquamarine; */
}

.blog-item-title {
	min-width: 100%;
	color: #1f222e;
	font-size: clamp(1.5rem, 2.5vw, 4rem);
	font-weight: 600;
	height: 100rem;
	text-decoration: none;
}

.blog-item-title:hover {
	color: blue;
	cursor: pointer;
	text-decoration: underline;
	text-decoration-color: blue;
	transition: 100ms ease-in;
}
.blog-item-wrapper:hover {
	box-shadow: rgba(75, 75, 75, 0.35) 0px 5px 15px;

	transition: 300ms ease-in;
}
@media screen and (max-width: 650px) {
	.blog-item-wrapper {
		/* background-color: aqua; */
		max-width: 90vw;
	}
}
