@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700;900&display=swap");
* {
	padding: 0;
	margin: 0;
	font-family: "Roboto", sans-serif;
}
* {
	/* background-color: rgba(0, 0, 0, 0.22); */
	/* border: solid 1px lightblue; */
}
.app-wrapper {
	display: flex;
	flex-direction: column;
	/* background-color: aqua; */
	align-items: center;
	justify-content: center;
}
