.markdown-body {
	/* background-color: aqua; */
	/* padding: 0.5rem; */
	box-sizing: border-box;
}
/* .markdown-body * {
	max-width: 100%;
	margin-bottom: 0.5rem;
	margin-top: 1rem;
	border: 1px solid blue;
} */
.markdown-body > * {
	color: #1f222e;
	font-family: Consolas, monaco, monospace;
	margin-top: 0;
	margin-bottom: 16px;
	line-height: 23px;
	overflow: auto;
}
.markdown-body p {
	margin: 0.5rem;
}

.markdown-body h1,
h2,
h3 {
	padding-bottom: 0.3em;
	margin-top: 24px;
	margin-bottom: 16px;
	font-weight: 600;
	line-height: 1.25;
}
.markdown-body h1 {
	padding-bottom: 0.3em;
	font-size: 2em;
	border-bottom: 1px solid hsl(210deg 18% 83%);
}
.markdown-body h2 {
	font-size: 1.5em;
	border-bottom: 1px solid rgb(216 222 228);
}
.markdown-body h2,
.markdown-body h3 {
	font-size: 1.25em;
}
/* ul and ol list */
.markdown-body > ul,
.markdown-body > ol {
	padding-left: 2em;
}
.markdown-body > ul {
	display: block;
	list-style-type: disc;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 40px;
}
.markdown-body > ol {
	display: block;
	list-style-type: decimal;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 40px;
}
.markdown-body li + li {
	margin-top: 0.25em;
}
.markdown-body > ul > li,
.markdown-body > ol > li {
	display: list-item;
	text-align: -webkit-match-parent;
}

/* ul and ol list ends */

/* img */
.markdown-body img {
	max-width: 40%;
	box-sizing: content-box;
	background-color: var(--color-canvas-default);
}

.markdown-body > pre {
	padding: 16px;
	overflow: auto;
	font-size: 90%;
	line-height: 1.45;
	background-color: #f6f8fa;
	border-radius: 6px;
}
.markdown-body > pre > code {
	/* background-color: aqua; */
	color: #2c2c2c;
}
@media screen and (max-width: 650px) {
	.markdown-body img {
		max-width: 95%;
	}
}
