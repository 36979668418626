.blogPageWrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.blogPageContainer > h1,
.blogPageContainer > h5,
.blogPageContainer > p {
	color: #1f222e;
}
.blogPageContainer > h5 {
	margin-bottom: 1rem;
	font-size: 0.7em;
	color: #1f222ea5;
	font-weight: bold;
}
.blogPageContainer {
	width: 60%;
}
@media screen and (max-width: 650px) {
	.blogPageContainer {
		width: 90%;
	}
}
